/**
 * 客服消息详情api接口统一管理
 */
import { get, post } from './http'

/**
  * 创建客服消息
  * @param {*} data
  * @returns
  */
export const createCustomer = (data) => post('/customer/createCustomer', data)

/**
  * 创建客服消息
  * @param {*} data
  * @returns
  */
export const editCustomer = (data) => get('/customer/editCustomer', data)


/**
 * 预览搜索昵称
 * @param {*} data
 * @returns
 */
export const getOffersList = (data) => get('/user/selectAll', data)

/**
  * 发送预览
  * @param {*} data
  * @returns
  */
export const sendPreview = (data) => post('/wx/sendPreview', data)


/**
 * 搜索全部公众号分类
 * @param {*} data
 * @returns
 */
export const syncCustomer = (data) => get('customer/syncCustomer', data)
