/**
 * 主菜单    用户管理api接口统一管理
 */
import { get, post } from './http'

// ===============================部门管理========================================

/**
  * 查询所有部门
  * @param {*} data
  * @returns
  */
export const SelectDeptAll = (data) => get('/dept/SelectDeptAll', data)

/**
  * 添加部门
  * @param {*} data
  * @returns
  */
export const insertDept = (data) => post('/dept/insertDept', data)

/**
  * 删除部门
  * @param {*} data
  * @returns
  */
export const deleteDept = (data) => get('/dept/deleteDept', data)

/**
  * 修改部门名称
  * @param {*} data
  * @returns
  */
 export const updateDeptName = (data) => get('/dept/updateDeptName', data)


// / ============================菜单权限管理===========================================

/**
  * 查询所有的菜单权限数据
  * @param {*} data
  * @returns
  */
export const selectPermAll = (data) => get('/perm/selectPermAll', data)

/**
  * 添加的菜单权限
  * @param {*} data
  * @returns
  */
export const insertPerm = (data) => post('/perm/insertPerm', data)

/**
  * 删除菜单权限数据
  * @param {*} data
  * @returns
  */
export const deltePerm = (data) => get('/perm/deltePerm', data)
/**
  * 修改菜单权限数据
  * @param {*} data
  * @returns
  */
export const editPerm = (data) => post('/perm/updatePerm', data)


// / ============================角色管理===========================================


/**
  * 查询全部角色数据
  * @param {*} data
  * @returns
  */
export const selectAllRole = (data) => get('/role/selectAllRole', data)


/**
  * 编辑角色数据
  * @param {*} data
  * @returns
  */
export const editRole = (data) => get('/role/editRole', data)

/**
  * 添加角色权限数据
  * @param {*} data
  * @returns
  */
export const insertRole = (data) => post('/role/insertRole', data)

/**
  * 修改角色权限数据
  * @param {*} data
  * @returns
  */
export const updateRole = (data) => post('/role/updateRole', data)

/**
  * 删除角色数据
  * @param {*} data
  * @returns
  */
export const deleteRole = (data) => get('/role/deleteRole', data)

// / ============================用户管理===========================================

/**
  * 删除用户数据
  * @param {*} data
  * @returns
  */
export const selectAllUser = (data) => get('/user/selectAllUser', data)


/**
  * 删除用户数据
  * @param {*} data
  * @returns
  */
export const insertUser = (data) => post('/user/insertUser', data)


/**
  * 停用或启用  用户
  * @param {*} data
  * @returns
  */
export const startAndStopUser = (data) => get('/user/startAndStopUser', data)


/**
  * 删除用户
  * @param {*} data
  * @returns
  */
export const deleteUser = (data) => get('/user/deleteUser', data)


/**
  * 修改用户数据
  * @param {*} data
  * @returns
  */
export const updateUser = (data) => post('/user/updateUser', data)


/**
  * 分配公众号
  * @param {*} data
  * @returns
  */
export const distAccount = (data) => post('/user/distAccount', data)

/**
  * 查看公众号权限
  * @param {*} data
  * @returns
  */
export const editAccount = (data) => get('/user/editAccount', data)

/**
  * 删除公众号权限
  * @param {*} data
  * @returns
  */
export const deleteAccount = (data) => get('/user/deleteAccount', data)
