<template>
  <!-- 部门树组件 -->
  <div>
    <el-tree
      :data="treeData"
      node-key="id"
      default-expand-all="true"
      :props="defaultProps"
      @node-click="handleNodeClick"
    >
    </el-tree>
  </div>
</template>

<script>
import { reactive, toRefs, inject, watch, watchEffect } from 'vue'
export default {
  name: 'departmentTree',
  components: {},
  // 接收页面的传值
  props: ['data'],
  setup(props, context) {
    const state = reactive({
      // 接收传入的树形数据
      treeData: props.data,
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'label',
      },
      // 点击事件
      handleNodeClick: (data) => {
        // 传出树形的数据
        context.emit('getTreeDataFun', data)
      },
    })

    watch(props, (newProps, olProps) => {
      state.treeData = newProps.data
    })
    return { ...toRefs(state) }
  },
}
</script>

<style lang="scss" scoped></style>
