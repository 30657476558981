<template>
  <!-- 全选公众号组件 -->
  <div>
    <div class="selectcentent flex">
      <!-- 左 -->
      <div class="selectcentent-left">
        <div class="selectcentent-left-inpt">
          <el-autocomplete
            class="inline-input"
            v-model="input"
            :fetch-suggestions="querySearch"
            placeholder="搜索公众号"
            :trigger-on-focus="false"
            @select="handleSelect"
            size="mini"
          ></el-autocomplete>
        </div>
        <!-- 根据分类名称判断是否选中了 -->
        <!-- <div
          :class="data[0].fl == pitchTencentArr.fl ? 'select-left-all active' : 'select-left-all'"
          @click="seltCentent(data[0])"
        >
          {{ data[0].fl }} (0/{{ data[0].arr.length }})
        </div> -->
        <div class="select-left-itembox">
          <!-- 根据分类名称判断是否选中了 -->
          <div
            :class="
              item.name == pitchTencentArr.name ? 'select-left-item active' : 'select-left-item'
            "
            v-for="item in data"
            @click="seltCentent(item)"
          >
            {{ item.name }} ({{ item.default }}/ {{ item.totalSize }})
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div style="width:100%">
        <div v-for="items in data" :key="items.id">
          <!-- 根据分类名称判断是否选中了 -->
          <div class="selectcentent-right" v-if="items.name == pitchTencentArr.name">
            <div>
              <el-checkbox
                v-model="items.id"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
            <!-- 公众号列表 -->
            <div class="tencentList flex">
              <!-- 单个公众号 -->
              <!-- pitchArr.includes(i.id) 判断是选中了该公众号 -->
              <div
                :class="
                  pitchArr.includes(i.id) ? 'tencentData alcenter actives' : 'tencentData alcenter'
                "
                v-for="i in items.list"
                @click="activeTendentIndex(i.id)"
              >
                <div><img :src="i.headImg" class="img flex" /></div>
                <div class="tencentFont flex">{{ i.nickName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下边的人数 -->
    <div class="button flex">
      <!-- ，预计共发送给{{}}人 -->
      <div>已选{{ pitchArr.length }}个公众号</div>
      <div>
        <el-button type="success" plain size="mini" @click="confirm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, watch, watchEffect } from 'vue'
import { syncCustomer } from '../request/newsCdDetails'
import { ElMessage } from 'element-plus'
export default {
  name: 'selececentent',
  components: {},
  props: ['type'],
  setup(props, context) {
    // 引用外部组件的 tencentObj 这是公众号详情的内容
    let tencentId = ''

    if (inject('tencentObj').value) {
      tencentId = inject('tencentObj').value.id
    }
    const state = reactive({
      // 全选默认值
      isIndeterminate: false,
      // 选中的公众号值,
      pitchArr: [],
      // 存储选中分类后的公众号数组
      pitchTencentArr: [],
      // 存储全部公众号的值用于搜索
      allTencentArr: [],
      // 输入框
      input: '',
      // 全选
      checked: false,
      data: [],

      // 选择分类
      seltCentent: (val) => {
        // 选中当前分类的时候存储当前分类的公众号
        state.pitchTencentArr = val
      },

      // 确定按钮
      confirm: () => {
        if (state.pitchArr.length < 1) {
          ElMessage({
            type: 'error',
            message: '请先选择公众号',
          })
        } else {
          context.emit('confirmFum', state.pitchArr)
          state.pitchArr = []
        }
      },
      // 选择公众号单选
      activeTendentIndex: (val) => {
        // 判断该公众号是否选中，选中就删除，否则就添加
        const index = state.pitchArr.indexOf(val)

        if (index > -1) {
          // 大于0 代表 存在
          state.pitchArr.splice(index, 1)
        } else {
          state.pitchArr.push(val)
        }
      },

      // 全选
      handleCheckAllChange: (val) => {
        if (val) {
          // 如果是全选的情况下，循环当前分类的数组 挨个对比选中的数组里边的ID，有就跳过，没有就添加
          state.pitchTencentArr.list.forEach((element) => {
            const index = state.pitchArr.indexOf(element.id)

            if (index === -1) {
              state.pitchArr.push(element.id)
            }
          })
        } else {
          // 如果取消全选了 就拿到选中当前分类的ID 挨个对比，删除存在的
          state.pitchTencentArr.list.forEach((element) => {
            const index = state.pitchArr.indexOf(element.id)

            if (index !== -1) {
              state.pitchArr.splice(index, 1)
            }
          })
        }
      },

      // 获取全部数据的值
      getAllData: () => {
        let datas = {}

        if (props.type) {
          datas = {
            accountId: tencentId,
          }
        } else {
          datas = {}
        }

        syncCustomer(datas).then((res) => {
          state.data = res.data.data
          // 必须把第一条数据赋值给pitchTencentArr做默认选中
          state.pitchTencentArr = state.data[0]
          state.allTencentArr = state.data[0]
        })
      },
    })

    state.getAllData()
    // 监听公众号选中数据
    watch(state.pitchArr, (newProps, olProps) => {
      // 每次选中公众号发生改变就让default=0
      state.data.forEach((item, data) => {
        item.default = 0
        // 循环list拿到公众号数据
        item.list.forEach((element) => {
          // 循环选中的公众号数组
          olProps.forEach((o) => {
            // 如果有相等的id  就让item.default  +1
            if (o === element.id) {
              item.default += 1
            }
          })
        })
      })
    })
    // 带搜索建议输入框方法
    const createFilter = (queryString) => {
      return (item) => {
        return item.value.toUpperCase().match(queryString.toUpperCase())
      }
    }
    const querySearch = (queryString, cb) => {
      // 新增一个value属性，因为这个组件只能显示value
      state.allTencentArr.list.forEach((item) => {
        item.value = item.nickName
      })
      const results = queryString
        ? state.allTencentArr.list.filter(createFilter(queryString))
        : state.allTencentArr.list
      // 调用 callback 返回建议列表的数据

      cb(results)
    }
    const handleSelect = (item) => {
      const data = {
        name: '搜索',
        id: 9999,
        list: [item],
        default: 0,
        totalSize: 1,
      }

      if (state.data[0].name === '搜索') {
        state.data[0] = data
      } else {
        state.data.unshift(data)
      }
      state.pitchTencentArr = data
    }

    return { ...toRefs(state), querySearch, handleSelect }
  },
}
</script>
<style lang="scss" scoped>
.selectcentent-right {
  width: 100%;
}
.selectcentent {
  height: 420px;
}
.selectcentent-left {
  width: 210px;
  margin-right: 15px;
  padding-right: 5px;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
}
.select-left-all,
.select-left-item {
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  color: #1a1a1a;
  border-radius: 2px;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
}
.actives {
  border: 1px solid #3b74ff !important;
}
.active,
.select-left-item:hover {
  background: #3b74ff;
  cursor: pointer;
  color: #fff;
}

.select-left-itembox {
  width: 100%;
  margin-top: 10px;
  height: 40vh;
  overflow-y: auto;
}
.button {
  justify-content: space-between;
}

.tencentList {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 39vh;
  overflow-y: auto;
}
.tencentData:hover {
  cursor: pointer;
  // border: 1px solid #3b74ff;
}
.tencentData {
  border: 1px solid #fff;
  align-content: center;
  background-color: rgb(245, 245, 245);
  position: relative;
  width: 23.5%;
  margin-right: 8px;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 10px;
  :nth-child(4*n) {
    margin-right: 0;
  }
}

.tencentFont {
  margin-left: 6px;
  align-self: center;
  font-size: 16px;
}

.img {
  width: 36px;
  margin-left: 8px;
}
</style>
