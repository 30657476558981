import { get, post } from '../https.js'

// 剪辑分配投放
export const clipAllocation = (data) => post('/clipPitcher/clipAllocation', data)

// 删除分配
export const deleteAllocation = (data) => post('/clipPitcher/deleteAllocation', data)

// 查询已分配数据
export const selectAllocationList = (data) => get('/clipPitcher/selectAllocationList', data)

// 查询分配的请求参数
export const selectAllocationParam = (data) => get('/clipPitcher/selectAllocationParam', data)

// 查询是否有分配权限
export const selectClipRole = (data) => get('/clipPitcher/selectClipRole', data)

// 查询所有剪辑
export const selectClipList = (data) => get('/clipPitcher/selectClipList', data)

