<template>
  <!-- 用户管理 -->
  <div class="paddbox autobox">
    <div>
      <el-card>
        <div class="flex">
          <!-- 部门树形 -->
          <div>
            <!-- 部门列表显示的树形组件 -->
            <div class="leftData">
              <departmentTree
                :data="departmentData"
                @getTreeDataFun="getTreeDataFun"
              ></departmentTree>
            </div>
          </div>
          <!-- 右边 -->
          <div class="rightData">
            <div class="addBtn flex">
              <div >
              <el-button type="primary" size="mini" @click="openAddUser()"
                >添加用户</el-button
              >
            </div>
            <div class="flex">
              <div><el-input
               v-model="nickName2"
               style="width: 240px"
              placeholder="搜索用户名称"
              clearable
                 /></div>
              <div>
                <el-button type="primary"  @click="selectDeptDataFun()"
                >查询用户</el-button
              >
              </div>
            </div>
            </div>

            <el-divider></el-divider>
            <!-- 表格 -->
            <div>
              <el-table :data="userData" border style="width: 100%">
                <el-table-column prop="id" label="序号" width="80">
                </el-table-column>
                <el-table-column prop="username" label="用户名" width="180">
                </el-table-column>
                <el-table-column prop="nickName" label="姓名" width="120">
                </el-table-column>
                <el-table-column prop="code" label="编号" width="120">
                </el-table-column>
                <el-table-column prop="roleName" label="角色" width="120">
                </el-table-column>
                <el-table-column
                  prop="loginTime"
                  label="最后登录时间"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="status" label="状态" width="80">
                  <template #default="scope">
                    <div
                      :style="
                        scope.row.status == 1
                          ? 'color: rgb(103, 194, 58)'
                          : 'color: rgb(245, 108, 108)'
                      "
                    >
                      {{ scope.row.status == 1 ? '启用' : '禁用' }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template #default="scope">
                    <div>
                      <el-button
                        v-if="scope.row.status == 0"
                        type="primary"
                        size="mini"
                        @click="changeAccountViewFun(scope.row,'更改')"
                        >启用</el-button
                      >
                      <el-button
                        v-if="scope.row.status == 1"
                        type="warning"
                        size="mini"
                        @click="changeAccountViewFun(scope.row,'更改')"
                        >停用</el-button
                      >
                      <el-button
                        type="info"
                        size="mini"
                        @click="openUpdateFun(scope.row)"
                        >修改</el-button
                      >
                      <!-- <el-button
                        type="danger"
                        size="mini"
                        @click="deleteUserFun(scope.row)"
                        >删除</el-button
                      > -->
                      <el-button
                        type="success"
                        size="mini"
                        @click="allotCentent(scope.row)"
                        >分配公众号</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="lookCentent(scope.row)"
                        >查看公众号</el-button
                      >

                      <el-button
                        v-if="
                          scope.row.roleName == '剪辑' ||
                            scope.row.roleName == '管理员'
                        "
                        type="success"
                        size="mini"
                        @click="openClipView()"
                        >分配投放</el-button
                      >
                      <el-button
                        v-if="
                          scope.row.roleName == '剪辑' ||
                            scope.row.roleName == '管理员'
                        "
                        type="primary"
                        size="mini"
                        @click="openSelectClipView()"
                        >查看投放分配</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="pagination">
              <pagination
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :total="total"
                :pagesize="pagesize"
                :currentPage="currentPage"
              ></pagination>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div>
      <!-- 分配弹窗  -->
      <el-dialog title="剪辑分配" v-model="addPitcherVisibles">
        <div>
          <el-card class="box-card">
            <div>
              <el-tabs v-model="methodType">
                <el-tab-pane label="根据部门查看" name="0">
                  <div class="flex">
                    <div class="Ftree">
                      <el-tree
                        :data="deptDate"
                        @node-click="handleNodeClick"
                        default-expand-all="ture"
                      ></el-tree>
                      <!-- :props="defaultProps" -->
                    </div>
                    <div>
                      <div class="flex">
                        <div>
                          <el-input
                            placeholder="请输入投放姓名"
                            v-model="fuzzyName"
                            clearable
                          >
                          </el-input>
                        </div>
                        <div>
                          <el-button
                            @click="selectAndDeptIdFun()"
                            type="primary"
                            >查询</el-button
                          >
                        </div>
                      </div>
                      <div class="table">
                        <el-table
                          ref="multipleTable"
                          :data="DeptTableDate"
                          tooltip-effect="dark"
                          style="width: 100%"
                          @selection-change="handleSelectionChange"
                        >
                          <el-table-column type="selection" width="55">
                          </el-table-column>
                          <el-table-column
                            prop="nickName"
                            label="姓名"
                            width="120"
                          >
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                    <div>
                      <h> 剪辑：</h>
                      <el-select
                        v-model="cid"
                        clearable
                        placeholder="请选择剪辑"
                      >
                        <el-option
                          v-for="item in ClipList"
                          :key="item.value"
                          :label="item.id"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="addTitleBtn flex">
                    <div>
                      <el-button @click="ChangeAddPitcherVisibles()"
                        >取消</el-button
                      >
                    </div>
                    <div>
                      <el-button type="primary" @click="confirmDeptFun()"
                        >确定</el-button
                      >
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="根据名称搜索" name="1">
                  <div class="flex">
                    <div>
                      <el-input
                        placeholder="请输入投放姓名"
                        v-model="fuzzyName2"
                        clearable
                      >
                      </el-input>
                    </div>
                    <div class="table">
                      <el-button @click="selectAndNickNameFun()" type="primary"
                        >查询</el-button
                      >
                    </div>
                    <div>
                      <h> 剪辑：</h>
                      <el-select
                        v-model="cid"
                        clearable
                        placeholder="请选择剪辑"
                      >
                        <el-option
                          v-for="item in ClipList"
                          :key="item.value"
                          :label="item.id"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div>
                    <el-table
                      ref="multipleTable"
                      :data="UserTableDate"
                      tooltip-effect="dark"
                      style="width: 100%"
                      @selection-change="handleSelectionChange2"
                    >
                      <el-table-column type="selection" width="55">
                      </el-table-column>
                      <el-table-column prop="nickName" label="姓名" width="120">
                      </el-table-column>
                    </el-table>
                  </div>

                  <div class="addTitleBtn flex">
                    <div>
                      <el-button @click="ChangeAddPitcherVisibles()"
                        >取消</el-button
                      >
                    </div>
                    <div>
                      <el-button type="primary" @click="confirmDeptFun2()"
                        >确定</el-button
                      >
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </div>
      </el-dialog>
      <!-- 查看分配弹窗  -->
      <el-dialog title="查看剪辑分配" v-model="selectPitcherVisibles">
        <div class="sleClip flex">
          <div>
            <el-select
              v-model="selectPitcher"
              clearable
              placeholder="请选择投放"
              @change="selectAllocationListFun()"
            >
              <el-option
                v-for="item in clipParam.pitcher"
                :key="item.value"
                :label="item.id"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select
              v-model="selectClip"
              clearable
              placeholder="请选择剪辑"
              @change="selectAllocationListFun()"
            >
              <el-option
                v-for="item in clipParam.clip"
                :key="item.value"
                :label="item.id"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-table :data="clipTable" border style="width: 100%">
            <el-table-column prop="pname" label="投放名称" width="180">
            </el-table-column>
            <el-table-column prop="cname" label="剪辑名称" width="120">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <div>
                  <el-button
                    type="danger"
                    size="mini"
                    @click="deleteClipFun(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <!-- 添加弹窗  -->
      <el-dialog title="添加用户" v-model="addUserVisibles">
        <div>
          <div>
            <el-form label-width="100px" class="demo-ruleForm">
              <el-form-item label="姓名：">
                <el-input v-model="userName"></el-input>
              </el-form-item>
              <el-form-item label="用户名：">
                <el-input v-model="loginName"></el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="name">
                <el-input v-model="loginPassword"></el-input>
              </el-form-item>
              <el-form-item label="备注信息：" prop="name">
                <el-input v-model="remark"></el-input>
              </el-form-item>
              <el-form-item label="所属部门：" prop="name" v-if="radio != 0">
                <div class="belong-text" @click="clickDiv">
                  {{
                    selectDeptName == '' ? '请选择所属部门！！' : selectDeptName
                  }}
                </div>
              </el-form-item>
              <el-form-item label="选择角色：" prop="name">
                <el-select v-model="roleValue" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="addUserFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="addUserCancel()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 修改弹窗  -->
      <el-dialog title="修改用户" v-model="updateUserVisibles">
        <div>
          <div>
            <el-form label-width="100px" class="demo-ruleForm">
              <el-form-item label="姓名：">
                <el-input v-model="updataUser.userName" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户名：">
                <el-input v-model="updataUser.loginName" disabled></el-input>
              </el-form-item>
              <el-form-item label="备注信息：" prop="name">
                <el-input v-model="updataUser.remark"></el-input>
              </el-form-item>
              <el-form-item label="所属部门：" prop="name" v-if="radio != 0">
                <div class="belong-text" @click="clickDiv">
                  {{
                    selectDeptName == '' ? updataUser.deptName : selectDeptName
                  }}
                </div>
              </el-form-item>
              <el-form-item label="选择角色：" prop="name">
                <el-select v-model="updataUser.roleId" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="updateUserFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="updateCancelFun()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 添加部门选择部门弹窗 -->
      <el-dialog title="请选择部门" v-model="selectVisibles">
        <div>
          <departmentTree
            :data="departmentData"
            @getTreeDataFun="getTreeDataFun"
          ></departmentTree>
        </div>
      </el-dialog>

      <!-- 更改账户状态 -->
      <el-dialog title="更改账户状态" v-model="changeAccountView">
        <div>
          <div>确定要更改----'{{userInfo.nickName}}'----账户状态？</div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                v-if="userInfo.status == 0"
                type="primary"
                size="mini"
                @click="startAndStopUserFun(userInfo)"
                >启用</el-button
                      >
               <el-button
                 v-if="userInfo.status == 1"
                 type="warning"
                 size="mini"
                 @click="startAndStopUserFun(userInfo)"
                 >停用</el-button
                      >

              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="changeAccountViewFun('取消','取消')"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 选择公众号 -->
      <el-dialog title="选择公众号" v-model="selectTencentVisibles">
        <div>
          <selectcentent @confirmFum="confirmFum"></selectcentent>
        </div>
      </el-dialog>

      <!-- 查看公众号删除公众号 -->
      <el-dialog title="查看公众号" v-model="lookTencentVisibles">
        <div>
          <el-table :data="userTencent" border style="width: 100%" height="800">
            <el-table-column prop="nickName" label="公众号名称" width="180">
            </el-table-column>
            <el-table-column prop="userName" label="分配人" width="180">
            </el-table-column>
            <el-table-column prop="createTime" label="分配时间" width="180">
            </el-table-column>
            <el-table-column prop="chapterTitle" label="操作">
              <template #default="scope">
                <el-button
                  type="text"
                  @click="DeleteTencent(scope.row)"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import pagination from '@/components/pagination.vue' // 导入分页组件
import departmentTree from '@/components/departmentTree.vue' // 引入树形组件
import {
  selectAllRole,
  selectPermAll,
  SelectDeptAll,
  selectAllUser,
  insertUser,
  startAndStopUser,
  deleteUser,
  updateUser,
  editAccount,
  distAccount,
  deleteAccount,
} from '@/request/userManagement'
import selectcentent from '@/components/selectcentent.vue'

import {
  clipAllocation,
  deleteAllocation,
  selectAllocationList,
  selectAllocationParam,
  selectClipRole,
  selectClipList,
} from '@/request/new/clipPitcher'

import {
  queryOrganization,
  selectAndDeptId,
  selectAndNickName,
} from '@/request/new/videoBatchManagement'

import { ElMessage } from 'element-plus'
import { reactive, toRefs } from 'vue'
import { stringify } from 'qs'

export default {
  name: 'userManagement',
  components: { departmentTree, pagination, selectcentent },
  setup() {
    const state = reactive({
      // 公众号数组
      userTencent: [],
      // 控制同步公众号显示
      selectTencentVisibles: false,
      // 查看公众号显示
      lookTencentVisibles: false,
      // 姓名
      userName: '',
      // 用户名
      loginName: '',
      // 密码
      loginPassword: '',
      // 备注信息
      remark: '',
      // 添加用户的所属部门的Id
      selectDeptId: '',
      // 添加用户的所属部门的名称
      selectDeptName: '',
      // 角色选择器数据
      options: [],
      // 选中的角色
      roleValue: '',
      // 查询部门的用户
      deptUser: '',
      // 修改用户控制
      updateUserVisibles: false,
      // 选择部门控制
      selectVisibles: false,
      // 添加弹窗控制
      addUserVisibles: false,
      //分配剪辑弹窗控制
      addPitcherVisibles: false,
      //查看剪辑弹窗控制
      selectPitcherVisibles: false,
      //控制账户状态控制
      changeAccountView:false,
      // 剪辑选择器
      ClipList: [],
      // 选中的剪辑
      cid: '',
      //要查的用户名
      nickName2:'',
      // 用户表格数据
      userData: [],
      // 部门树形数据
      departmentData: [],
      // 条数
      pagesize: 20,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 100,
      // 修改的数据
      updataUser: {
        // 用户的id
        id: '',
        // 姓名
        userName: '',
        // 用户名
        loginName: '',
        // 备注信息
        remark: '',
        // 用户id
        uid: '',
        // 角色
        roleId: '',
        deptName: '',
      },
      // 选择类别
      methodType: '0',
      // 查看剪辑分配表格数据
      clipTable: [],
      // 查看剪辑的请求参数
      clipParam: {},
      //选中的投放
      selectPitcher: '',
      //选中的剪辑
      selectClip: '',
      // 组织数据
      deptDate: [],
      // 部门表格数据
      DeptTableDate: [],
      // 用户表格数据
      UserTableDate: [],
      // 被选中的部门
      checkDeptId: '',
      // 模糊查询的名称
      fuzzyName: '',
      // 根据名称查询
      fuzzyName2: '',
      // 被选中的账户人id
      multipleSelection: [],
      // 名字 被选中的账户人id
      multipleSelection2: [],
      //更改账户状态方法
      changeAccountViewFun:(data,type)=>{
        console.log(data)
        if(type=='更改'){
          state.userInfo=data
          state.changeAccountView=!state.changeAccountView
        }
        if(type=='取消'){
          state.changeAccountView=false
          state.userInfo=''
        }
        
      },
      //储存更改储存数据
      userInfo:'',
      // 剪辑分配的确定
      confirmDeptFun: () => {
        var msg = ''
        if (state.cid == '') {
          msg = '请选择剪辑'
        }
        if (state.multipleSelection == '') {
          msg = '请选择投放'
        }
        if (msg.length > 0) {
          return ElMessage({
            type: 'error',
            message: msg,
          })
        }
        const datas = {
          cid: state.cid,
          aidList: state.multipleSelection,
        }
        state.clipAllocationFun(datas)
      },
      // 剪辑分配的确定2
      confirmDeptFun2: () => {
        var msg = ''
        if (state.cid == '') {
          msg = '请选择剪辑'
        }
        if (state.multipleSelection2 == '') {
          msg = '请选择投放'
        }
        if (msg.length > 0) {
          return ElMessage({
            type: 'error',
            message: msg,
          })
        }
        const datas = {
          cid: state.cid,
          aidList: state.multipleSelection2,
        }
        state.clipAllocationFun(datas)
      },
      clipAllocationFun: (datas) => {
        clipAllocation(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: res.msg,
          })
          state.ChangeAddPitcherVisibles()
          state.multipleSelection2 = []
          state.multipleSelection = []
        })
      },
      // 节点点击时被回调
      handleNodeClick(a, b, c) {
        console.log('a', a)
        console.log('b', b)
        console.log('c', c)
        state.checkDeptId = a.id
        state.selectAndDeptIdFun()
      },
      // 查看分配剪辑弹窗的值
      ChangeSelectPitcherVisibles: () => {
        state.selectPitcherVisibles = !state.selectPitcherVisibles
      },
      // 改变分配剪辑弹窗的值
      ChangeAddPitcherVisibles: () => {
        state.addPitcherVisibles = !state.addPitcherVisibles
      },
      // 分配剪辑按钮
      openClipView: () => {
        selectClipRole().then((res) => {
          state.fuzzyName = ''
          state.fuzzyName2 = ''
          state.ChangeAddPitcherVisibles()
          state.queryOrganizationFun()
          state.selectClipListFun()
        })
      },
      // 查询剪辑
      selectClipListFun: () => {
        selectClipList().then((res) => {
          state.ClipList = res.data
        })
      },
      // 根据姓名查询
      selectAndNickNameFun: () => {
        if (state.fuzzyName2.length == 0) {
          return ElMessage({
            type: 'error',
            message: '姓名不能为空',
          })
        }
        const datas = { nickName: state.fuzzyName2 }

        selectAndNickName(datas).then((res) => {
          state.UserTableDate = res.data
        })
      },
      // 表格选框
      handleSelectionChange: (val) => {
        state.multipleSelection = []
        val.forEach((item) => {
          state.multipleSelection.push(item.id)
        })
      },
      // 表格选框
      handleSelectionChange2: (val) => {
        state.multipleSelection2 = []
        val.forEach((item) => {
          state.multipleSelection2.push(item.id)
        })
      },
      // 查询当前用户所在组织 最上级别
      queryOrganizationFun: () => {
        queryOrganization().then((res) => {
          state.deptDate = res.data
          state.checkDeptId = state.deptDate[0].id
          state.selectAndDeptIdFun()
        })
      },
      // 根据部门查用户
      selectAndDeptIdFun: () => {
        const datas = {
          deptId: state.checkDeptId,
          nickName: state.fuzzyName,
        }

        selectAndDeptId(datas).then((res) => {
          state.DeptTableDate = res.data
        })
      },
      // 查看分配剪辑按钮
      openSelectClipView: () => {
        selectClipRole().then((res) => {
          state.selectAllocationParamFun()
          state.selectAllocationListFun()
          state.ChangeSelectPitcherVisibles()
        })
      },
      // 查询请求参数
      selectAllocationParamFun: () => {
        selectAllocationParam().then((res) => {
          state.clipParam = res.data
        })
      },
      // 查询分配
      selectAllocationListFun: () => {
        const datas = {
          pid: state.selectPitcher,
          cid: state.selectClip,
        }
        selectAllocationList(datas).then((res) => {
          state.clipTable = res.data
        })
      },
      // 删除剪辑分配
      deleteClipFun: (data) => {
        console.log('删除剪辑分配', data)
        const datas = {
          cid: data.clipUserId,
          pid: data.pitcherUserId,
        }
        deleteAllocation(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
          state.selectAllocationListFun()
          state.selectAllocationParamFun()
        })
      },
      // 分配公众号
      allotCentent: (row) => {
        state.uid = row.id
        state.selectTencentVisibles = true
      },
      // 查看公众号
      lookCentent: (row) => {
        state.uid = row.id
        editAccount({ userId: state.uid }).then((res) => {
          state.userTencent = res.data
        })
        state.lookTencentVisibles = true
      },
      // 删除公众号权限
      DeleteTencent: (row) => {
        deleteAccount({ id: row.id }).then(() => {
          editAccount({ userId: state.uid }).then((res) => {
            state.userTencent = res.data
          })
          ElMessage({
            type: 'success',
            message: '删除公众号成功',
          })
        })
      },
      // 接收从组件中获取被选中的公众号
      confirmFum: (val) => {
        const datas = {
          userId: state.uid,
          ads: JSON.stringify(val),
        }

        distAccount(datas).then(() => {
          ElMessage({
            type: 'success',
            message: '添加公众号成功',
          })
        })
      },
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        selectAllUserFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        selectAllUserFun()
      },
      // 打开修改弹窗
      openUpdateFun: (data) => {
        state.selectDeptName = ''
        state.updataUser.roleId = data.roleId
        state.updataUser.userName = data.nickName
        state.updataUser.loginName = data.username
        state.updataUser.remark = data.note
        state.updataUser.id = data.id
        state.updataUser.deptName = data.deptName
        state.selectDeptId = data.deptId
        state.updateUserVisibles = true
      },
      // 修改 ---》 取消
      updateCancelFun: () => {
        state.updateUserVisibles = false
      },
      // 暂停或启用  用户
      startAndStopUserFun: (data) => {
        const datas = {
          id: data.id,
        }

        if (data.status == 1) {
          datas.status = 0
        } else {
          datas.status = 1
        }
        startAndStopUser(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '修改状态成功',
          })
          state.changeAccountView=false
          selectAllUserFun()
        })
      },
      // 删除用户
      deleteUserFun: (data) => {
        if (data.status == 1) {
          ElMessage({
            type: 'error',
            message: '请先禁用再删除',
          })
        } else {
          const datas = {
            id: data.id,
          }

          deleteUser(datas).then((res) => {
            ElMessage({
              type: 'success',
              message: '删除用户成功',
            })
            selectAllUserFun()
          })
        }
      },
      // 修改
      updateUserFun: () => {
        if (
          state.updataUser.userName == '' ||
          state.updataUser.loginName == '' ||
          state.updataUser.remark == ''
        ) {
          ElMessage({
            type: 'error',
            message: '数据不能为空',
          })
        } else {
          const datas = {
            id: state.updataUser.id,
            nickName: state.updataUser.userName,
            username: state.updataUser.loginName,
            note: state.updataUser.remark,
            roleId: state.updataUser.roleId,
            deptId: state.selectDeptId,
          }

          updateUser(datas).then((res) => {
            state.updateUserVisibles = false
            selectAllUserFun()
            ElMessage({
              type: 'success',
              message: '修改用户成功',
            })
          })
        }
      },
      // 添加用户的确定
      addUserFun: () => {
        if (
          state.userName == '' ||
          state.loginName == '' ||
          state.loginPassword == '' ||
          state.remark == '' ||
          state.selectDeptId == '' ||
          state.roleValue == ''
        ) {
          ElMessage({
            type: 'error',
            message: '数据不能为空',
          })
        } else {
          const datas = {
            nickName: state.userName,
            username: state.loginName,
            password: state.loginPassword,
            note: state.remark,
            deptId: state.selectDeptId,
            roleId: state.roleValue,
          }

          state.insertUserFun(datas)
        }
      },
      // 添加用户
      insertUserFun: (data) => {
        insertUser(data).then((res) => {
          state.addUserVisibles = false

          selectAllUserFun()
          ElMessage({
            type: 'success',
            message: '添加用户成功',
          })
        })
      },
      // 添加用户的取消
      addUserCancel: () => {
        state.addUserVisibles = false
        state.selectDept = ''
      },
      // 接收选择的部门数据
      getTreeDataFun: (val) => {
        state.selectVisibles = false
        if (state.addUserVisibles == true || state.updateUserVisibles == true) {
          // 是添加里面的选着部门
          state.selectDeptId = val.id
          state.selectDeptName = val.label
        } else {
          // 是点击查看部门人员
          state.deptUser = val.id
          state.selectDeptDataFun()
        }
      },
      // 打开添加弹窗
      openAddUser: () => {
        state.userName = ''
        state.loginName = ''
        state.loginPassword = ''

        state.remark = ''
        state.selectDeptId = ''
        state.roleValue = ''
        state.selectDeptName = ''
        state.addUserVisibles = true
      },
      // 打开选择部门弹窗
      clickDiv: () => {
        state.selectVisibles = true
      },
      // 查询所属部门的用户
      selectDeptDataFun: () => {
        const datas = {
          deptId: state.deptUser,
          currenPage: state.currentPage,
          size: state.pagesize,
          nickName:state.nickName2
        }

        selectAllUser(datas).then((res) => {
          state.userData = res.data.data
          state.currentPage = res.data.currenPage
          state.total = res.data.totalSize
          state.nickName2=''
        })
      },
    })

    // 查询所有角色数据
    const selectAllRoleFun = () => {
      selectAllRole().then((res) => {
        state.options = res.data
      })
    }

    selectAllRoleFun()

    // 查询所有部门
    const SelectDeptAllFun = () => {
      SelectDeptAll().then((res) => {
        state.departmentData = res.data
      })
    }

    SelectDeptAllFun()

    // 查询所有用户
    const selectAllUserFun = () => {
      const datas = {
        currenPage: state.currentPage,
        size: state.pagesize,
      }
      if (state.deptUser != '') {
        datas.deptId = state.deptUser
      }

      selectAllUser(datas).then((res) => {
        state.userData = res.data.data
        state.currenPage = res.data.currenPage
        state.total = res.data.totalSize
      })
    }

    selectAllUserFun()

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
    }
  },
}
</script>

<style lang="scss" scoped>
.rightData {
  width: 80%;
  margin-left: 100px;
}

.add-button-yes {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.belong-text {
  width: 200px;
  padding-left: 20px;
  box-sizing: border-box;
  // background-color: rgb(144, 147, 153);
  border: 1px solid #e6e6e6;
  cursor: pointer;
  border-radius: 5px;
  height: 50px;
}

.sleClip {
  margin-bottom: 10px;

  > div {
    margin-right: 20px;
  }
}

.addTitleBtn {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.Ftree {
  margin-right: 30px;
}

.table {
  margin-right: 200px;
}

.add-button-yes {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.addBtn{
  justify-content:space-between
}
</style>
